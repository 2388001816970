import React, { ComponentPropsWithoutRef, isValidElement, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Box, BoxProps, FormControl, FormControlProps, FormLabel, Text } from "@chakra-ui/react";
import { mergeRefs } from "react-merge-refs";

export interface LabelProps extends Pick<FormControlProps, "isRequired"> {
  /** Field name. */
  name?: string;
  /** Field label. */
  label?: string;
  outerProps?: BoxProps;
  labelProps?: ComponentPropsWithoutRef<"label">;
  id?: string;
  shouldPassRegister?: boolean;
  children?: React.ReactNode;
  renderOriginal?: boolean;
  formControlProps?: FormControlProps;
}

export const RegisteredField: React.ForwardRefExoticComponent<LabelProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    const {
      label,
      outerProps,
      labelProps,
      name = "",
      shouldPassRegister = true,
      renderOriginal = false,
      isRequired,
      formControlProps,
    } = props;
    const {
      register,
      formState: { isSubmitting, errors },
    } = useFormContext();

    const error = errors[name]?.message || errors[name];
    const id = props.id || name;

    const renderChildren = useCallback(
      (child) => {
        if (renderOriginal) return child;

        if (React.isValidElement(child)) {
          const { ref: registerRef = undefined, ...restRegistered } = shouldPassRegister
            ? register(name)
            : {};

          return React.cloneElement<any>(child, {
            disabled: isSubmitting,
            id,
            ...restRegistered,
            ref: registerRef ? mergeRefs([ref, registerRef]) : ref,
          });
        }
      },
      [id, isSubmitting, name, ref, register, renderOriginal, shouldPassRegister],
    );

    return (
      <Box {...outerProps}>
        <FormControl isInvalid={!!error} isRequired={isRequired} {...formControlProps}>
          {label && (
            <FormLabel htmlFor={id} {...labelProps}>
              {label}:
            </FormLabel>
          )}

          {renderChildren(children)}

          {error && (
            <Text role="alert" color="red">
              {typeof error === "string" || isValidElement(error) ? error : <>Error</>}
            </Text>
          )}
        </FormControl>
      </Box>
    );
  },
);
