export const avatarStyles = {
  components: {
    Avatar: {
      container: {
        borderRadius: "50px",
      },

      variants: {
        primary: {
          container: {
            borderRadius: "20px",
          },
          label: {},
        },
      },
    },
  },
};
