import { PropsWithoutRef, ReactNode, useMemo, useState } from "react";
import { FormProvider, useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Box, Button, FormControl, Icon } from "@chakra-ui/react";
import LoadingButton from "../Button/LoadingButton";
import { FloppyDisk } from "iconoir-react";

export interface FormProps<S extends z.ZodType<any, any>>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit"> {
  /** All your form fields */
  children?: ReactNode;
  /** Text to display in the submit button */
  submitText?: string;
  schema?: S;
  onSubmit: (values: z.infer<S>, ctx: UseFormReturn) => Promise<void | OnSubmitResult>;
  initialValues?: UseFormProps<z.infer<S>>["defaultValues"];
}

interface OnSubmitResult {
  FORM_ERROR?: string;
  [prop: string]: any;
}

export const FORM_ERROR = "FORM_ERROR";

export function Form<S extends z.ZodType<any, any>>({
  children,
  submitText,
  schema,
  initialValues,
  onSubmit,
  ...props
}: FormProps<S>) {
  const ctx = useForm<z.infer<S>>({
    mode: "onBlur",
    resolver: schema ? zodResolver(schema) : undefined,
    defaultValues: initialValues,
  });
  const [formError, setFormError] = useState<string | null>(null);

  const formErrorString = useMemo(
    () =>
      Object.entries(ctx.formState.errors).reduce(
        (str, [field, errObj]) => str + `${field}: ${errObj?.message}`,
        "",
      ),
    [ctx.formState.errors],
  );

  return (
    <FormProvider {...ctx}>
      <form
        onSubmit={ctx.handleSubmit(async (values) => {
          const result = (await onSubmit(values, ctx)) || {};

          let wasError = false;
          for (const [key, value] of Object.entries(result)) {
            if (key === FORM_ERROR) {
              setFormError(value);
              wasError = true;
            } else {
              ctx.setError(key as any, {
                type: "submit",
                message: value,
              });
            }
          }
          if (!wasError) {
            setFormError(null);
          }
        })}
        className="form"
        {...props}
      >
        <FormControl>
          {/* Form fields supplied as children are rendered here */}
          {children}
        </FormControl>

        {(formError || formErrorString) && (
          <Box role="alert" style={{ color: "red" }} mt={4}>
            {formError || formErrorString}
          </Box>
        )}

        {submitText && (
          <LoadingButton
            fontFamily={"heading"}
            mt={6}
            type="submit"
            isLoading={ctx.formState.isSubmitting}
            marginLeft={"auto"}
            color="white"
            fontWeight="bold"
            mb="24px"
            leftIcon={<FloppyDisk />}
          >
            {submitText}
          </LoadingButton>
        )}
      </form>
    </FormProvider>
  );
}

export default Form;
