import { mode } from "@chakra-ui/theme-tools";
import { DARK_BG, WHITE_BG } from "./foundations/colors";

export const globalStyles = {
  styles: {
    global: (props) => ({
      body: {
        bg: "background",
        fontWeight: 500,
      },
      "div:target": {
        border: "2px solid red",
      },
    }),
  },
};
