import { createMultiStyleConfigHelpers, defineStyleConfig } from "@chakra-ui/react";
import { colors } from "../../foundations/colors";
import { inputAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const Input = defineStyleConfig({
  variants: {
    bold: {
      field: {
        border: `1px solid ${colors.gray["200"]}`,
        fontSize: "1rem",
      },
    },

    filled: definePartsStyle((props) => ({
      field: {
        borderColor: props.colorMode === "light" ? "gray.100" : "gray.700",
        outline: "none",
        borderWidth: "1px",

        _invalid: {
          borderColor: "red",
        },

        _focusVisible: {
          borderColor: props.colorMode === "light" ? "blue.500" : "blue.300",
          boxShadow: "none",
        },
      },
    })),
  },

  baseStyle: {
    field: {
      background: "white",
      _placeholder: {
        color: "gray.400",
      },
      borderRadius: "8px",
      fontSize: "0.9rem",
      // padding: '0 1.2rem',
      height: "46px",

      /*
        _dark: {
          bgColor: "blue.900",
          borderColor: "gray.600",
        },
*/

      _invalid: {
        borderColor: "red",
      },

      _focusVisible: {
        borderColor: "red",
        boxShadow: "none",
      },
    },
  },

  sizes: {
    sm: {
      field: {
        height: "38px",
        borderRadius: "6px",
        fontSize: "12px",
      },
    },
  },

  defaultProps: {
    variant: "filled",
  },
});
