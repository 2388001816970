export enum Roles {
  REPORTER = "REPORTER",
  INFLUENCER = "INFLUENCER",
  PERSON = "PERSON",
}

export enum UserTypes {
  USER = "USER",
  SUPERUSER = "SUPERUSER",
}

export enum IDPSites {
  GOOGLE = "GOOGLE",
}

export const allRoles = Object.values(Roles);
