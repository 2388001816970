import { mode } from "@chakra-ui/theme-tools";
import { semanticTokens } from "../foundations/colors";

export const linkStyles = {
  components: {
    Link: {
      baseStyle: (props) => ({
        color: mode(
          semanticTokens.colors.primaryFontColor.default,
          semanticTokens.colors.primaryFontColor._dark,
        )(props),
        textDecoration: "underline",
        _hover: {},
        _focus: {
          // boxShadow: "none",
        },
      }),

      variants: {
        unstyled: {
          textDecoration: "none",
          _hover: {
            textDecoration: "none",
          },
        },
      },
    },
  },
};
