import React from "react";
import * as _chakra_ui_system from "@chakra-ui/system";
import { useFormContext } from "react-hook-form";
import { Input, InputProps } from "@chakra-ui/react";

import { LabelProps, RegisteredField } from "app/core/components/Form/RegisteredField";

export interface LabeledTextFieldProps extends InputProps, Omit<LabelProps, "children"> {
  type?: "text" | "password" | "email" | "number" | "hidden" | "tel";
}

export type TextFieldWithAs = _chakra_ui_system.ComponentWithAs<"input", LabeledTextFieldProps>;

export const LabeledTextField: TextFieldWithAs = (props) => {
  const { outerProps, name = "", label, labelProps, isRequired, ...restProps } = props;
  const { register } = useFormContext();
  const id = props.id || name;

  return (
    <RegisteredField
      {...{ outerProps, name, label, labelProps, id, isRequired }}
      renderOriginal={true}
    >
      <Input {...restProps} isRequired={isRequired} {...register(name)} />
    </RegisteredField>
  );
};

export default LabeledTextField;
