import { SystemStyleObject } from "@chakra-ui/react";

export const tabs: SystemStyleObject = {
  components: {
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: "primary",
          },
        },
        tabpanel: {
          padding: 0,
          paddingTop: 4,
        },
      },
    },
  },
};
