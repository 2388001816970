import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import { inputAnatomy } from "@chakra-ui/anatomy";
import { checkboxTheme } from "./checkbox";
import { Input } from "./input";

export const form = {
  components: {
    FormLabel: {
      baseStyle: {
        marginTop: "1rem",
        marginBottom: ".4rem",

        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: "12px",
      },
    },

    Input,

    Textarea: {
      baseStyle: {
        _placeholder: {
          color: "gray.400",
        },
      },
    },

    Checkbox: checkboxTheme,
  },
};
