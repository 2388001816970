import React from "react";
import { Box, BoxProps, Icon, IconProps } from "@chakra-ui/react";
import ThreeDots from "app/core/assets/svg/three-dots.svg";
import Image from "next/image";
import { LoaderSvg } from "app/core/components/Loader/LoaderSVG";

interface Props extends IconProps {
  containerProps?: BoxProps;
}

export const Loader: React.FC<Props> = ({ containerProps, ...restProps }) => (
  <Icon width={40} as={LoaderSvg} fill="primary" {...restProps} />
);

export default Loader;
