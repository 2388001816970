import React, { useMemo } from "react";
import { Routes } from "@blitzjs/next";
import NextLink, { LinkProps } from "next/link";
import { Button, Link, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { useRouter } from "next/router";

export interface InternalLinkProps extends Omit<LinkProps, "as">, Omit<ChakraLinkProps, "href"> {
  children: React.ReactNode;
  chakraLinkProps?: ChakraLinkProps;
  isBlock?: boolean;
  passQuery?: boolean;
  asButton?: boolean;
}

export const InternalLink: React.FC<InternalLinkProps> = ({
  children,
  chakraLinkProps,
  isBlock,
  onClick,
  passQuery = true,
  asButton,
  ...linkProps
}) => {
  const { query } = useRouter();

  return (
    // @ts-ignore
    <Link
      variant="unstyled"
      as={NextLink}
      display={isBlock ? "block" : "inline"}
      onClick={onClick}
      {...linkProps}
      {...chakraLinkProps}
    >
      {children}
    </Link>
  );
};
