import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  label: {},
  control: {
    borderColor: "secondaryFontColor",
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
