import { AuthClientPlugin } from "@blitzjs/auth";
import { setupBlitzClient } from "@blitzjs/next";
import { BlitzRpcPlugin } from "@blitzjs/rpc";

export const authConfig = {
  cookiePrefix: "aprety",
};

export const { withBlitz } = setupBlitzClient({
  plugins: [
    AuthClientPlugin(authConfig),
    BlitzRpcPlugin({
      reactQueryOptions: {
        queries: {
          refetchOnWindowFocus: process.env.NODE_ENV !== "development",
        },
      },
    }),
  ],
});
