const PanelContent = {
  baseStyle: {
    ms: "auto",
    me: "auto",
    ps: {
      md: "15px",
    },
    pe: "0",
  },
};

export const PanelContentComponent = {
  components: {
    PanelContent,
  },
};
