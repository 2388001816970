import { AuthenticationError, AuthorizationError } from "blitz";
import { withBlitz } from "app/blitz-client";
import { useQueryErrorResetBoundary } from "@blitzjs/rpc";
import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps, Routes } from "@blitzjs/next";
import { appWithTranslation } from "next-i18next";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { RecoilURLSyncJSONNext } from "recoil-sync-next";
import { Settings } from "luxon";
import { QueryClientConfig } from "@tanstack/react-query";

import LoginForm from "app/auth/components/LoginForm";

import theme from "app/core/theme/theme";
import { useRouter } from "next/router";
import { PageLoader } from "app/core/components/Loader/PageLoader/PageLoader";
import React from "react";
import { afterLoginRedirect } from "../app/core/recoil/redirect";
import { Fonts } from "../app/core/theme/foundations/fonts";

Settings.defaultLocale = "cs";

function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
    >
      <CSSReset />
      <RecoilRoot>
        <RecoilURLSyncJSONNext location={{ part: "queryParams" }}>
          <ChakraProvider theme={theme}>
            <PageLoader />
            <Fonts />
            {getLayout(<Component {...pageProps} />)}
          </ChakraProvider>
        </RecoilURLSyncJSONNext>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default withBlitz(appWithTranslation(App));

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const router = useRouter();
  const setAfterLoginUrl = useSetRecoilState(afterLoginRedirect);

  console.log("RootErrorFallback", { error });
  if (error instanceof AuthenticationError) {
    void router.push(Routes.LoginPage());

    return <LoginForm onSuccess={resetErrorBoundary} />;
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    );
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    );
  }
}
