/* eslint-disable import/no-anonymous-default-export */
import { foundations } from "@chakra-ui/theme/foundations";
import { tailwindColors } from "app/core/theme/foundations/tailwindColors";
import { rgba, toRgba, transparentize } from "color2k";

const { colors: chakraColors } = foundations;

export const brand = {
  300: "#7C82D6",
  400: "#4C56E4",
  500: "#1B019B",
  700: "#2B1D82",
};

export const colors = {
  brand,
  currentColor: brand["700"],
  vanilla: "#FFFCEF",
  green: {
    900: "#0E2324",
  },
  beige: "#E8E2DB",
  red: {
    50: "#FFE7E5",
    100: "#FFBCB8",
    200: "#FF918A",
    300: "#FF5247",
    400: "#FF3A2E",
    500: "#FF5247",
    600: "#CC0C00",
    700: "#990900",
    800: "#660600",
    900: "#330300",
  },
  blue: {
    ...brand,
    900: "#011627",
  },

  gray: {
    ...chakraColors.gray,
    50: "#ECECEC",
    100: "#E0E0E0",
    500: "#BDBDBD",
  },

  /* materialGray: {
    900: "#0F172A",
    /!*    700: "#1B202B"*!/

    600: "#475569",
    700: "#334155",
    800: "#1e293b",
  },*/

  //materialGray: chakraColors.gray
};
export const WHITE_BG = "gray.50";
export const DARK_BG = "gray.800";

export const PRIMARY_FONT_COLOR = colors.gray["800"];
export const PRIMARY_FONT_COLOR_DARK = colors.gray["100"];

export const semanticTokens = {
  colors: {
    background: {
      default: WHITE_BG,
      _dark: DARK_BG,
    },
    lightBackground: {
      default: "gray.50",
      _dark: "gray.900",
    },
    secondaryBackground: {
      default: "gray.100",
      _dark: "gray.800",
    },
    tertiaryBackground: {
      default: "gray.300",
      _dark: "gray.700",
    },

    primary: {
      default: colors.brand["500"],
      _dark: colors.brand["400"],
    },

    primaryFontColor: {
      default: PRIMARY_FONT_COLOR,
      _dark: PRIMARY_FONT_COLOR_DARK,
    },
    secondaryFontColor: {
      default: colors.gray["600"],
      _dark: colors.gray["400"],
    },
    muted: {
      default: transparentize(PRIMARY_FONT_COLOR, 0.4),
      _dark: transparentize(PRIMARY_FONT_COLOR_DARK, 0.4),
    },
  },
};
