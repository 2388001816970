import { Global } from "@emotion/react";

export const font = {
  fonts: {
    body: `'TT Wellingtons', sans-serif`,
    heading: `'TT Wellingtons', sans-serif`,
  },
};

export const Fonts = () => (
  <Global
    styles={`

    @font-face {
    font-family: 'TT Wellingtons';
    src: url('./fonts/TTWellingtons-Regular.eot');
    src: local('TT Wellingtons Regular'), local('TTWellingtons-Regular'),
        url('./fonts/TTWellingtons-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/TTWellingtons-Regular.woff2') format('woff2'),
        url('./fonts/TTWellingtons-Regular.woff') format('woff'),
        url('./fonts/TTWellingtons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('./fonts/TTWellingtons-Medium.eot');
    src: local('TT Wellingtons Medium'), local('TTWellingtons-Medium'),
        url('./fonts/TTWellingtons-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/TTWellingtons-Medium.woff2') format('woff2'),
        url('./fonts/TTWellingtons-Medium.woff') format('woff'),
        url('./fonts/TTWellingtons-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('./fonts/TTWellingtons-DemiBold.eot');
    src: local('TT Wellingtons DemiBold'), local('TTWellingtons-DemiBold'),
        url('./fonts/TTWellingtons-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/TTWellingtons-DemiBold.woff2') format('woff2'),
        url('./fonts/TTWellingtons-DemiBold.woff') format('woff'),
        url('./fonts/TTWellingtons-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('./fonts/TTWellingtons-BoldItalic.eot');
    src: local('TT Wellingtons Bold Italic'), local('TTWellingtons-BoldItalic'),
        url('./fonts/TTWellingtons-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/TTWellingtons-BoldItalic.woff2') format('woff2'),
        url('./fonts/TTWellingtons-BoldItalic.woff') format('woff'),
        url('./fonts/TTWellingtons-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

      `}
  />
);
