import { colors } from "app/core/theme/foundations/colors";

export const tagStyles = {
  components: {
    Tag: {
      variants: {
        primary: {
          container: {
            backgroundColor: colors.brand["400"],
            color: "white",
            borderRadius: "20px",
          },
          label: {},
        },
      },
    },
  },
};
