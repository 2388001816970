import { theme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { semanticTokens } from "../foundations/colors";

const commonHeadingStyles = {
  textTransform: "capitalize",
  fontWeight: 600,
};

const textVariants = {
  h1: {
    ...commonHeadingStyles,
    fontWeight: "bold",
    fontSize: "64px",
  },
  h2: {
    ...commonHeadingStyles,
    fontSize: "2xl",
    fontWeight: "48px",
  },
  h3: {
    ...commonHeadingStyles,
    fontSize: "32px",
  },
  h4: {
    ...commonHeadingStyles,
    fontSize: "24px",
  },
  h5: {
    ...commonHeadingStyles,
    fontSize: "20px",
  },
};

export const textStyles = {
  components: {
    Text: {
      baseStyle: (props) => ({
        color: mode(
          semanticTokens.colors.primaryFontColor.default,
          semanticTokens.colors.primaryFontColor._dark,
        )(props),
      }),

      variants: {
        ...textVariants,

        description: {
          fontSize: theme.fontSizes["md"],
          marginTop: theme.space[2],
        },

        muted: (props) => ({
          color: props.colorMode !== "dark" ? "gray.600" : "gray.300",
        }),

        error: {
          color: "accent.600",
        },

        secondary: (props) => ({
          color: mode(
            semanticTokens.colors.secondaryFontColor.default,
            semanticTokens.colors.secondaryFontColor._dark,
          )(props),
        }),

        label: {
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    Heading: {
      variants: {
        ...textVariants,
      },
    },
    Link: {
      baseStyle: (props) => ({
        color: mode(
          semanticTokens.colors.primaryFontColor.default,
          semanticTokens.colors.primaryFontColor._dark,
        )(props),
        textDecoration: "underline",
        _hover: {},
        _focus: {
          // boxShadow: "none",
        },
      }),

      variants: {
        unstyled: {
          textDecoration: "none",
          _hover: {
            textDecoration: "none",
          },
        },
      },
    },
  },
};
