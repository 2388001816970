const Card = {
  baseStyle: {},
  variants: {
    panel: (props) => ({
      bg: props.colorMode === "dark" ? "gray.700" : "white",
      width: "100%",
      boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
      borderRadius: "15px",
    }),
    sleek: {
      container: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "tertiaryBackground",
        borderRadius: 10,
      },
    },
  },
  defaultProps: {},
};

export const CardComponent = {
  components: {
    Card,
  },
};
