import { AuthenticationError } from "blitz";
import Link from "next/link";
import { Routes } from "@blitzjs/next";
import { useMutation } from "@blitzjs/rpc";
import { LabeledTextField } from "app/core/components/Form/LabeledTextField";
import { Form, FORM_ERROR } from "app/core/components/Form/Form";
import login from "app/auth/mutations/login";
import { Login } from "app/auth/validations";
import { InternalLink } from "../../core/components/Link/InternalLink";
import { useTranslation } from "next-i18next";

type LoginFormProps = {
  onSuccess?: () => void;
};

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login);
  const { t } = useTranslation("auth");

  return (
    <div>
      <Form
        submitText={t("login_submit")}
        schema={Login}
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          try {
            await loginMutation(values);
            props.onSuccess?.();
          } catch (error: any) {
            if (error instanceof AuthenticationError) {
              return { [FORM_ERROR]: t("login_invalid_credentials") };
            } else {
              return {
                [FORM_ERROR]: t("login_unexpected_error") + error.toString(),
              };
            }
          }
        }}
      >
        <LabeledTextField
          name="email"
          label={t("login_email_label")}
          placeholder={t("login_email_placeholder")}
        />
        <LabeledTextField
          name="password"
          label={t("login_password_label")}
          type="password"
          placeholder={t("login_password_placeholder")}
        />
        <div>
          <InternalLink
            href={Routes.ForgotPasswordPage()}
            chakraLinkProps={{ textDecoration: "underline", color: "muted" }}
          >
            {t("login_forgot_password")}
          </InternalLink>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
