import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { globalStyles } from "app/core/theme/styles";
import { font } from "app/core/theme/foundations/fonts";
import { breakpoints } from "app/core/theme/foundations/breakpoints";
import { buttonStyles, iconButtonStyles } from "app/core/theme/components/button";
import { badgeStyles } from "app/core/theme/components/badge";
import { linkStyles } from "app/core/theme/components/link";
import { drawerStyles } from "app/core/theme/components/drawer";
import { CardComponent } from "app/core/theme/additions/card/Card";
import { CardBodyComponent } from "app/core/theme/additions/card/CardBody";
import { CardHeaderComponent } from "app/core/theme/additions/card/CardHeader";
import { MainPanelComponent } from "app/core/theme/additions/layout/MainPanel";
import { PanelContentComponent } from "app/core/theme/additions/layout/PanelContent";
import { PanelContainerComponent } from "app/core/theme/additions/layout/PanelContainer";
import { colors, semanticTokens } from "app/core/theme/foundations/colors";
import { form } from "app/core/theme/components/form/form";
import { textStyles } from "app/core/theme/components/text";
import { avatarStyles } from "app/core/theme/components/avatar";
import { tagStyles } from "app/core/theme/components/tag";
import { tabs } from "app/core/theme/components/tabs";

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: false,
};

const theme = extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  { colors },
  font, // Global styles,
  {
    components: {
      Button: buttonStyles,
    },
  },
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component,
  avatarStyles,
  tagStyles,
  tabs,
  form,
  { config },
  textStyles,
  { semanticTokens },
);

type Theme = typeof theme;

export type { Theme };

export { theme };

export default theme;
