import { brand } from "app/core/theme/foundations/colors";
import Loader from "app/core/components/Loader/Loader";
import { defineStyleConfig } from "@chakra-ui/react";

const secondaryButtonStyles = {
  bg: "transparent",

  border: `1px solid ${brand["500"]}`,
  color: "brand.500",

  _hover: {
    bgColor: "brand.300",
    color: "white",

    "& svg": {
      color: "white",
    },
  },
  "& svg": {
    color: brand["500"],
  },

  _dark: {
    borderColor: "brand.300",
    color: "brand.300",

    "& svg": {
      color: brand["300"],
    },

    _hover: {
      color: "white",

      "& svg": {
        color: "white",
      },
    },
  },
};

export const HEIGHT_SM = "34px";
export const HEIGHT_MD = "43px";

export const buttonStyles = defineStyleConfig({
  variants: {
    primary: {
      bg: "brand.500",
      color: "white",

      _hover: {
        bg: "brand.400",
        "& svg": {},
      },

      "& svg": {
        color: "white",
      },

      _dark: {
        bg: "brand.400",

        _hover: {
          bg: "brand.300",
        },
      },
    },
    secondary: secondaryButtonStyles,

    outlined: secondaryButtonStyles,
  },
  baseStyle: {
    borderRadius: "10px",
    fontWeight: "500",
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    md: {
      height: HEIGHT_MD,
      borderRadius: "21px",
      px: "18px",
      fontSize: "14px",
    },
    sm: {
      height: HEIGHT_SM,
      borderRadius: "21px",
      fontSize: "12px",
    },
  },

  defaultProps: {
    // @ts-ignore
    spinner: <Loader width={7} containerProps={{ mt: 1 }} />,
    variant: "primary",
  },
});

export const iconButtonStyles = defineStyleConfig({
  sizes: {
    md: {
      width: "43px",
    },
    sm: {
      width: "34px",
    },
  },
});
