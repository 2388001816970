import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import Loader from "app/core/components/Loader/Loader";
import { is } from "@babel/types";

interface Props extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: React.FC<Props> = ({ isLoading, loading, children, ...restProps }) => (
  <Button
    isLoading={isLoading || loading}
    spinner={<Loader containerProps={{ mt: 1 }} width={30} />}
    {...restProps}
  >
    {children}
  </Button>
);

export default LoadingButton;
